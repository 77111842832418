import React, { useState } from "react";

import {
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  createTheme,
  ThemeProvider,
  TableHead,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import url from "../../Components/Service/Service";

const DepartmentPage = () => {
  const [formData, setFormData] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [image, setImage] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [previewImage, setPreviewImage] = useState(null); // New state for preview image
  const [doctors, setDoctors] = useState([]);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  // Handle text input change
  const handleTextChange = (e) => {
    setTextValue(e.target.value);
  };

  // Handle image upload and set preview
  const handleImageUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
      setPreviewImage(URL.createObjectURL(e.target.files[0])); // Preview image URL
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredDoctors = doctors.filter((doctor) =>
    doctor.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if image and text value are available
    if (!textValue || !image) {
      alert("Please enter a department and select an image");
      return;
    }

    try {
      // Create a form data object to hold the image and text
      const formData = new FormData();
      formData.append("department", textValue); // Append department text
      formData.append("image", image); // Append image file

      // Make a POST request to your backend API
      const response = await fetch(`${url}/departments/create-department`, {
        method: "POST",
        body: formData,
      });

      // Handle success response
      if (response.status === 200) {
        alert("Department saved successfully!");
        // Clear form data
        setTextValue("");
        setImage(null);
        setPreviewImage(null);
        togglePopup(); // Close popup
      }
    } catch (error) {
      console.error("Error saving department:", error);
      alert("An error occurred while saving the department.");
    }
  };
  const theme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#133680",
      },
      secondary: {
        main: "#f50057",
      },
      text: {
        primary: "#000000",
      },
    },
    typography: {
      fontFamily: "Montserrat",
    },
  });

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Manage Departments
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={togglePopup}
            >
              New
            </Button>
          </Stack>

          <p>Search for keyword</p>
          <input
            type="search"
            placeholder="Search by name"
            value={searchQuery}
            onChange={handleSearchChange}
            className="p-2 mb-4 w-1/3 border-2"
          />
          <button className="ml-9 px-8 py-2 bg-blue-500 text-white rounded-md">
            Search
          </button>

          <TableContainer>
            <Table>
              <TableHead className="opacity-55">
                <TableCell>ID</TableCell>
                <TableCell>CATEGORY NAME</TableCell>
              </TableHead>
              <TableBody>
                {filteredDoctors.map((doctor) => (
                  <TableRow key={doctor.id}>
                    <TableCell>{doctor.name}</TableCell>
                    {/* Add other doctor details here */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {showPopup && (
            <form onSubmit={handleSubmit}>
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-white shadow-lg rounded-lg p-6">
                  {/* <h2 className="text-lg font-bold">New Item</h2> */}
                  <input
                    type="text"
                    id="departmentName"
                    placeholder="Enter Department"
                    value={textValue}
                    onChange={handleTextChange}
                    className=" p-2 mb-4 w-full border-2"
                  />{" "}
                  <input
                    type="file"
                    accept="image/*"
                    id="departmentImage"
                    onChange={handleImageUpload}
                    className="border p-2 mb-4 w-full"
                  />
                  {/* Show image preview */}
                  {previewImage && (
                    <div className="mb-4 ">
                      <img
                        src={previewImage}
                        alt="Selected"
                        className="w-40 h-40 rounded-md mx-auto"
                      />
                    </div>
                  )}
                  <div className="flex items-center justify-between">
                    <button
                      className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                      type="submit"
                    >
                      Save
                    </button>
                    <button
                      className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                      onClick={togglePopup}
                    >
                      Close
                    </button>
                  </div>
                </div>
                {/* Overlay to darken the background */}
                {/* <div
                className="fixed inset-0  opacity-0"
                onClick={togglePopup}
              ></div> */}
              </div>
            </form>
          )}
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default DepartmentPage;
